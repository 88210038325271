  .app {
    background-color: #fff;
  }

  .app button {
    padding: 10px 15px;
    border: 2px solid #87ceeb;
    background-color: #f46d19;
    margin: 20px 0;
    border-radius: 10px;
    cursor: pointer;
    color:#fff;
    font-size: 18px;
    width: 100%;
  }
  .app #downloadWrapper {
    margin-top: 10px;
  }

  .close {
    display: none;
  }