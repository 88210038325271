.fZMnD {
    width: 85%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
}

.fZMnD, option {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    font-size: small;
}