.priceCategoryWrapper{
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
}
.priceCategoryHeader{
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.priceCategoryElement{
    display: flex;
    gap: 10px;
}

.selectedPrice{
    border: 1px solid #f26f21;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    gap: 5px;
}

/* .selectedPrice > input[type="radio"]:checked{
    background-color: #d1d3d1
} */

/* input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
} */

.selectedPrice > input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.commonSelected{
    border: 1px solid #999;
    padding: 10px 20px;
    display: flex ; 
    align-items: center;
    border-radius:4px;
    gap:5px;
}

.priceCategoryLabel{
    font-size: 16px;
    font-weight: 700;
    color: #000;
    line-height: 18.75px;
}

.pricingFormWrapper{
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #999999;
    border-radius: 4px;
    padding: 5px;
}