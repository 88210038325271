body {
  margin: 0;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #FFFFFF inset !important;
}

::-webkit-scrollbar {
  display: none;
}

.scrollbar_visible::-webkit-scrollbar {
  display: auto;
}

button {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-family: 'Roboto', 'serif', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  scroll-behavior: smooth;
}

@media screen and (min-width: 760px) {

  body {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(128, 128, 128, 0.1);
  }

 #root{
   max-width: 760px;
   background: #FFFFFF 0% 0% no-repeat padding-box;
 }

}